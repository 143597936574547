import React, { Component } from "react";
import Slider from "react-slick";
import { k7Teams } from "../../teamsData";

export default class Responsive extends Component {
  render() {
    var settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div
        className="slider-teams-wrapper"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <Slider {...settings}>
          {k7Teams.map((item, index) => (
            <div key={index} className="k7-team-card" data-aos="fade-up">
              <div>
                {" "}
                <div>
                  <img src={`${item.k7TeamLogo}`} alt="" />
                </div>
              </div>

              <div>
                <div className="k7-teams-name">{item.k7TeamName}</div>
                <ul className="k7-teams-social-icons">
                  <li
                    className={`${item.k7YoutubeIcon ? "" : "remove-padding"}`}
                  >
                    <a href={item.k7TeamYoutube} target="_blank">
                      <i className={`${item.k7YoutubeIcon}`}></i>
                    </a>
                  </li>
                  <li
                    className={`${item.k7DiscordIcon ? "" : "remove-padding"}`}
                  >
                    <a href={item.k7TeamDiscord} target="_blank">
                      <i className={`${item.k7DiscordIcon}`}></i>
                    </a>
                  </li>
                  <li
                    className={`${item.k7TwitterIcon ? "" : "remove-padding"}`}
                  >
                    <a href={item.k7TeamTwitter} target="_blank">
                      <i className={`${item.k7TwitterIcon}`}></i>
                    </a>
                  </li>
                  <li
                    className={`${item.k7TiktokIcon ? "" : "remove-padding"}`}
                  >
                    <a href={item.k7TeamTiktok} target="_blank">
                      <i className={`${item.k7TiktokIcon}`}></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
