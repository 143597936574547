import React, { Component } from "react";
import Slider from "react-slick";
import TestLogo from "../../../images/K7logo2.png";
import TestStaff from "../../../images/K7Team/Ryuk.png";

export default class Responsive extends Component {
  departmentThreeArray = [
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "FXSyntax",
      staffRole: "GFX Artist",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Vibez",
      staffRole: "GFX Artist",
    },
  ];

  render() {
    var settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="staff-wrapper">
        <div className="department-name">Graphic Artists</div>
        <Slider {...settings}>
          {this.departmentThreeArray.map((item, index) => (
            <div className="staff-card-wrapper" key={index}>
              <div className="staff-image">
                <img src={item.staffImage} alt=""></img>
              </div>

              <div className="staff-text-wrapper">
                <div className="k7-image">
                  <img src={item.logo} alt=""></img>
                </div>
                <div>
                  <div className="staff-name">{item.staffName}</div>
                  <div className="staff-role">{item.staffRole}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
