import star from "./images/K7TeamLogos/5star.png";
import kgk from "./images/K7TeamLogos/kgk.png";
import lk from "./images/K7TeamLogos/Lurk-Blue.png";
import m7 from "./images/K7TeamLogos/m7.png";
import nfb from "./images/K7TeamLogos/Nola.png";
import nta from "./images/K7TeamLogos/nt.png";
import tv from "./images/K7TeamLogos/tv.png";
import vg from "./images/K7TeamLogos/K7Logo.png";

export const leagueStandingsArray2 = [
  {
    leaguePos: "1",
    leagueTeam: "Nola Flight Black",
    leagueLogo: nfb,
    leagueWin: "10",
    leagueLoss: "2",
    leagueGP: "6",
  },

  {
    leaguePos: "2",
    leagueTeam: "Millennium 7",
    leagueLogo: m7,
    leagueWin: "9",
    leagueLoss: "3",
    leagueGP: "12",
  },

  {
    leaguePos: "3",
    leagueTeam: "Lurk Blue",
    leagueLogo: lk,
    leagueWin: "7",
    leagueLoss: "5",
    leagueGP: "12",
  },

  {
    leaguePos: "4",
    leagueTeam: "Team Veer",
    leagueLogo: tv,
    leagueWin: "7",
    leagueLoss: "5",
    leagueGP: "12",
  },

  {
    leaguePos: "5",
    leagueTeam: "Natural Talent",
    leagueLogo: nta,
    leagueWin: "6",
    leagueLoss: "6",
    leagueGP: "12",
  },

  {
    leaguePos: "6",
    leagueTeam: "Team KGK",
    leagueLogo: kgk,
    leagueWin: "5",
    leagueLoss: "7",
    leagueGP: "12",
  },

  {
    leaguePos: "7",
    leagueTeam: "Vanguard Gaming",
    leagueLogo: vg,
    leagueWin: "3",
    leagueLoss: "9",
    leagueGP: "12",
  },

  {
    leaguePos: "8",
    leagueTeam: "5 Star eSports",
    leagueLogo: star,
    leagueWin: "1",
    leagueLoss: "11",
    leagueGP: "12",
  },
];
