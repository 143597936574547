import React from "react";
import testLogo from "../../images/K7Logo.png";
import DA from "../../images/DA.png";
import Wardens from "../../images/wardens1.png";
import XDG from "../../images/XDGLogo.png";
import HSA from "../../images/HSA.png";

const K7Partners = () => {
  const k7Partners = [
    {
      k7PartnersLogo: Wardens,
      k7PartnersName: "Wardens",
      k7PartnersYoutube:
        "https://www.youtube.com/channel/UCoiOe9vLZKDPDdbwpv8qTww",
      k7PartnersDiscord: "https://discord.com",
      k7PartnersTwitter: "https://twitter.com/WardensEsports",
      // k7PartnersText:
      //   "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      //   "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      //   "pellentesque pulvinar pellentesque habitant morbi tristique" +
      //   "senectus et netus et malesuada fames ac turpis egestas maecenas" +
      //   "pharetra convallis posuere morbi leo urna molestie at elementum eu" +
      //   "facilisis sed odio morbi quis",
    },
    {
      k7PartnersLogo: DA,
      k7PartnersName: "Dark Assassins",
      // k7PartnersYoutube: "https://google.com",
      k7PartnersDiscord: "https://discord.gg/b3TWneMs7C",
      k7PartnersTwitter: "https://twitter.com/DarkAssassinCN",
      // k7PartnersText:
      //   "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      //   "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      //   "pellentesque pulvinar pellentesque habitant morbi tristique" +
      //   "senectus et netus et malesuada fames ac turpis egestas maecenas" +
      //   "pharetra convallis posuere morbi leo urna molestie at elementum eu" +
      //   "facilisis sed odio morbi quis",
    },
    {
      k7PartnersLogo: XDG,
      k7PartnersName: "Xalted Death Gaming",
      // k7PartnersYoutube: "https://google.com",
      k7PartnersDiscord: "https://discord.gg/2E4Kk67KMm",
      k7PartnersTwitter: "https://twitter.com/XaltedDeath",
      //   k7PartnersText:
      //     "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      //     "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      //     "pellentesque pulvinar pellentesque habitant morbi tristique" +
      //     "senectus et netus et malesuada fames ac turpis egestas maecenas" +
      //     "pharetra convallis posuere morbi leo urna molestie at elementum eu" +
      //     "facilisis sed odio morbi quis",
    },
    {
      k7PartnersLogo: HSA,
      k7PartnersName: "Hard Style Assassin",
      k7PartnersYoutube: "https://youtube.com/channel/UCWY5ewMx9rXoXh9yF2saFxg",
      k7PartnersDiscord: "https://discord.gg/ERwjUTh9xT",
      k7PartnersTwitter: "https://twitter.com/gaming_hsa",
      // k7PartnersText:
      //   "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      //   "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      //   "pellentesque pulvinar pellentesque habitant morbi tristique" +
      //   "senectus et netus et malesuada fames ac turpis egestas maecenas" +
      //   "pharetra convallis posuere morbi leo urna molestie at elementum eu" +
      //   "facilisis sed odio morbi quis",
    },
  ];

  return (
    <div className="k7-partners-wrapper">
      <div
        className="partner-title section-titles"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        Current <span>Partnered </span>Communities
      </div>
      <div className="k7-partner-cards-wrapper">
        {k7Partners.map((item, index) => (
          <div
            key={index}
            className="k7-partners-card"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div>
              <img src={item.k7PartnersLogo} alt="" />
            </div>
            <div className="partners-name">{item.k7PartnersName}</div>
            <br />
            <ul>
              <li>
                <a href={item.k7PartnersYoutube} className="fab fa-youtube"></a>
              </li>
              <li>
                <a href={item.k7PartnersDiscord} className="fab fa-discord"></a>
              </li>
              <li>
                <a href={item.k7PartnersTwitter} className="fab fa-twitter"></a>
              </li>
            </ul>
            <br />
            <div className="partners-text">{item.k7PartnersText}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default K7Partners;
