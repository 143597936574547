import React from "react";
import testLogo from "../../images/K7Logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../Footer";

const Sponsors = () => {
  AOS.init({ duration: 1500, once: true });

  const sponsorsArray = [
    {
      sponsorLogo: testLogo,
      sponsorsName: "Test Name1",
      sponsorsYoutube: "https://google.com",
      sponsorsDiscord: "https://discord.com",
      sponsorsTwitter: "https://twitter.com",
      sponsorText:
      "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      "pellentesque pulvinar pellentesque habitant morbi tristique" +
      "senectus et netus et malesuada fames ac turpis egestas maecenas"
    },
    {
      sponsorLogo: testLogo,
      sponsorsName: "Test Name2",
      sponsorsYoutube: "https://google.com",
      sponsorsDiscord: "https://discord.com",
      sponsorsTwitter: "https://twitter.com",
      sponsorText:
        "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      "pellentesque pulvinar pellentesque habitant morbi tristique" +
      "senectus et netus et malesuada fames ac turpis egestas maecenas"
    },
    {
      sponsorLogo: testLogo,
      sponsorsName: "Test Name3",
      sponsorsYoutube: "https://google.com",
      sponsorsDiscord: "https://discord.com",
      sponsorsTwitter: "https://twitter.com",
      sponsorText:
        "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      "pellentesque pulvinar pellentesque habitant morbi tristique" +
      "senectus et netus et malesuada fames ac turpis egestas maecenas"
    },
    {
      sponsorLogo: testLogo,
      sponsorsName: "Test Name4",
      sponsorsYoutube: "https://google.com",
      sponsorsDiscord: "https://discord.com",
      sponsorsTwitter: "https://twitter.com",
      sponsorText:
        "amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus" +
      "vitae congue mauris rhoncus aenean vel elit scelerisque mauris" +
      "pellentesque pulvinar pellentesque habitant morbi tristique" +
      "senectus et netus et malesuada fames ac turpis egestas maecenas"
    },
  ];

  return (
    <div className="sponsors-background">
      <div className="section-titles" data-aos="fade-up">Our Sponsors</div>
      <div className="sponsors-wrapper">
        {sponsorsArray.map((item, index) => (
          <div key={index} className="sponsors-card" data-aos="fade-up" data-aos-delay="50">
            <div>
              <img src={testLogo} alt="" />
            </div>
            <div className="sponsor-name">{item.sponsorsName}</div>
            <ul>
              <li>
                <a href={item.sponsorsYoutube}>
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href={item.sponsorsDiscord}>
                  <i className="fab fa-discord"></i>
                </a>
              </li>
              <li>
                <a href={item.sponsorsTwitter}>
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
            <div className="sponsor-text">{item.sponsorText}</div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Sponsors;
