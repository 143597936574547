import ctc from "./images/K7TeamLogos/ctc.png";
import doc from "./images/K7TeamLogos/DoC.png";
import nt from "./images/K7TeamLogos/newthreat.png";
import pul from "./images/K7TeamLogos/Pulsive-NA.png";
import syg from "./images/K7TeamLogos/syg.png";
import tfs from "./images/K7TeamLogos/tfs.png";
import zo from "./images/K7TeamLogos/z2o.png";
import ugk from "./images/K7TeamLogos/K7Logo.png";

export const leagueStandingsArray1 = [
  {
    leaguePos: "1",
    leagueTeam: "UGK eSports",
    leagueLogo: ugk,
    leagueWin: "12",
    leagueLoss: "0",
    leagueGP: "12",
  },

  {
    leaguePos: "2",
    leagueTeam: "Dawn of Chaos",
    leagueLogo: doc,
    leagueWin: "9",
    leagueLoss: "3",
    leagueGP: "12",
  },

  {
    leaguePos: "3",
    leagueTeam: "Team Final Strike",
    leagueLogo: tfs,
    leagueWin: "8",
    leagueLoss: "4",
    leagueGP: "12",
  },
  {
    leaguePos: "4",
    leagueTeam: "Command Then Conquer",
    leagueLogo: ctc,
    leagueWin: "7",
    leagueLoss: "5",
    leagueGP: "12",
  },
  {
    leaguePos: "5",
    leagueTeam: "Pulsive NA",
    leagueLogo: pul,
    leagueWin: "6",
    leagueLoss: "6",
    leagueGP: "12",
  },
  {
    leaguePos: "6",
    leagueTeam: "Zero2One",
    leagueLogo: zo,
    leagueWin: "3",
    leagueLoss: "9",
    leagueGP: "12",
  },
  {
    leaguePos: "7",
    leagueTeam: "New Threat",
    leagueLogo: nt,
    leagueWin: "2",
    leagueLoss: "10",
    leagueGP: "12",
  },
  {
    leaguePos: "8",
    leagueTeam: "Stole Your Glory",
    leagueLogo: syg,
    leagueWin: "1",
    leagueLoss: "11",
    leagueGP: "12",
  },
];
