import testLogo from "./images/K7Logo.png";
import star from "./images/K7TeamLogos/5star.png";
import ctc from "./images/K7TeamLogos/ctc.png";
import doc from "./images/K7TeamLogos/DoC.png";
import kgk from "./images/K7TeamLogos/kgk.png";
import lurk from "./images/K7TeamLogos/Lurk-Blue.png";
import m7 from "./images/K7TeamLogos/m7.png";
import nt from "./images/K7TeamLogos/newthreat.png";
import nola from "./images/K7TeamLogos/Nola.png";
import nta from "./images/K7TeamLogos/nt.png";
import pul from "./images/K7TeamLogos/Pulsive-NA.png";
import syg from "./images/K7TeamLogos/syg.png";
import tfs from "./images/K7TeamLogos/tfs.png";
import tv from "./images/K7TeamLogos/tv.png";
import zo from "./images/K7TeamLogos/z2o.png";

export const k7Teams = [
  {
    k7TeamLogo: star,
    k7TeamName: "5 Star eSports",
    k7TwitterIcon: "fab fa-twitter",
    k7DiscordIcon: "fab fa-discord",
    k7TeamTwitter: "https://twitter.com/5SEGaming",
  },
  {
    k7TeamLogo: ctc,
    k7TeamName: "Command Then Conquer",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/cTc_ORG",
  },
  {
    k7TeamLogo: doc,
    k7TeamName: "Dawn of Chaos",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/DawnofChaos_",
  },
  {
    k7TeamLogo: kgk,
    k7TeamName: "King Grant Killers",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/TeamKGK",
  },
  {
    k7TeamLogo: lurk,
    k7TeamName: "Lurk Blue",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/_LuRkOfficial",
  },
  {
    k7TeamLogo: m7,
    k7TeamName: "Millennium 7",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/OfficallyM7",
  },
  {
    k7TeamLogo: nt,
    k7TeamName: "New Threat",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/NewThreat",
  },
  {
    k7TeamLogo: nola,
    k7TeamName: "Nola Flight Black",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/NolaFlightGG",
  },
  {
    k7TeamLogo: nta,
    k7TeamName: "Natural Talent",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/4NaturalTalent",
  },
  {
    k7TeamLogo: pul,
    k7TeamName: "Pulsive NA",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/PulsiveNA",
  },
  {
    k7TeamLogo: syg,
    k7TeamName: "Stole Your Glory",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/SYG_org",
  },
  {
    k7TeamLogo: tfs,
    k7TeamName: "Team Final Strike",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/TM_FinalStrike",
  },
  {
    k7TeamLogo: tv,
    k7TeamName: "Team Veer",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/VeerTeam",
  },
  {
    k7TeamLogo: testLogo,
    k7TeamName: "UGK eSports",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/UGKEsports",
  },
  {
    k7TeamLogo: testLogo,
    k7TeamName: "Vanguard Gaming",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/VNGRDggs",
  },
  {
    k7TeamLogo: zo,
    k7TeamName: "Zero2One",
    k7TwitterIcon: "fab fa-twitter",
    k7TeamTwitter: "https://twitter.com/TeamZero2One",
  },
];
