import "./styles.css";
import Home from "./components/Home/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Menu from "./components/Menu";
// import Login from "./components/Login";
// import Register from "./components/Register";
import K7League from "./components/K7League/K7League";
import Contact from "./components/Contact/Contact";
import ClanCentral from "./components/ClanCentral/ClanCentral";
import Staff from "./components/Staff/Staff";
import Sponsors from "./components/Sponsors/Sponsors";

function App() {
  // const LoginContainer = () => (
  //   <div>
  //     <Route path="/login">
  //       <Login />
  //     </Route>
  //     {/* <Route path="/register">
  //       <Register />
  //     </Route> */}
  //   </div>
  // );

  // const RegistrationContainer = () => (
  //   <div>
  //     <Route path="/register">
  //       <Register />
  //     </Route>
  //   </div>
  // );

  const HomeController = () => (
    <div>
      <Menu />
      <Route path="/home">
        <Home />
      </Route>
    </div>
  );

  const ClanCentralController = () => (
    <div>
      <Menu />
      <Route path="/clan-central">
        <ClanCentral />
      </Route>
    </div>
  );

  const ContactController = () => (
    <div>
      <Menu />
      <Route path="/contact">
        <Contact />
      </Route>
    </div>
  );

  const StaffController = () => (
    <div>
      <Menu />
      <Route path="/Staff">
        <Staff />
      </Route>
    </div>
  );

  const SponsorshipController = () => (
    <div>
      <Menu />
      <Route path="/sponsors">
        <Sponsors />
      </Route>
    </div>
  );

  const DefaultContainer = () => (
    <div>
      <Menu />
      <Route path="/">
        <Home />
      </Route>
    </div>
  );

  const K7Controller = () => (
    <div>
      <Menu />
      <Route path="/K7League">
        <K7League />
      </Route>
    </div>
  );

  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Route exact path="/login" component={LoginContainer} /> */}
          <Route exact path="/" component={DefaultContainer}>
            <Redirect to="/home" />
          </Route>
          <Route exact path="/home" component={HomeController} />
          <Route exact path="/K7League" component={K7Controller} />
          <Route exact path="/Contact" component={ContactController} />
          <Route exact path="/clan-central" component={ClanCentralController} />
          <Route exact path="/Staff" component={StaffController} />
          <Route exact path="/sponsors" component={SponsorshipController} />
          {/* <Route component={Login} /> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
