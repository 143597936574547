import React, { Component } from "react";
import Slider from "react-slick";
import TestLogo from "../../../images/K7logo2.png";
import TestStaff from "../../../images/K7Team/Ryuk.png";

export default class Responsive extends Component {
  departmentTwoArray = [
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Kokkikodi",
      staffRole: "CEO, MGE",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "A Psycho",
      staffRole: "Socials Lead",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Goated Cole",
      staffRole: "Podcast Host",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "annaxx7",
      staffRole: "Intern",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Atomic",
      staffRole: "Intern",
    },
  
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Rushhh",
      staffRole: "Intern",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "xSneakster",
      staffRole: "Intern",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Kamaka",
      staffRole: "Observer CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "4Trix",
      staffRole: "Observer CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Exile",
      staffRole: "Observer",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Shifty",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Mcpogmeal",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "BrettFX",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "JayFlyT",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Tugboat",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "BignTallMatt",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "4Trix",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "Amuse",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "That1Guy",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "trev0rrr",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "The_GamingChef",
      staffRole: "CoDCaster",
    },
    {
      staffImage: TestLogo,
      logo: TestLogo,
      staffName: "CarboSZN ",
      staffRole: "CoDCaster",
    },


  ];

  render() {
    var settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="staff-wrapper">
        <div className="department-name">GameDay Operations Crew</div>
        <Slider {...settings}>
          {this.departmentTwoArray.map((item, index) => (
            <div className="staff-card-wrapper" key={index}>
              <div className="staff-image">
                <img src={item.staffImage} alt=""></img>
              </div>

              <div className="staff-text-wrapper">
                <div className="k7-image">
                  <img src={item.logo} alt=""></img>
                </div>
                <div>
                  <div className="staff-name">{item.staffName}</div>
                  <div className="staff-role">{item.staffRole}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
