import React from "react";
import DepartmentOne from "./StaffTeam/DepartmentOne";
import DepartmentTwo from "./StaffTeam/DepartmentTwo";
import DepartmentThree from "./StaffTeam/DepartmentThree";
import DepartmentFour from "./StaffTeam/DepartmentFour";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../Footer";

const Staff = () => {
  AOS.init({ duration: 1500 });

  return (
    <div className="staff-background">
      <DepartmentOne />
      <DepartmentTwo />
      <DepartmentThree />
      <DepartmentFour />
      <Footer />
    </div>
  );
};

export default Staff;
