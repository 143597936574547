import React from "react";

const AboutHome = () => {
  return (
    <div className="background-section-seperator home-about-wrapper">
      <div data-aos="fade-up" data-aos-delay="100" className="how-we-are">
        Who <span>We</span> Are
      </div>
      <div data-aos="fade-up" data-aos-delay="200" className="text-intro">
        Killjoy 7 is a media company focused around Gaming and building Gaming
        Platforms for players all over the world. Established in 2018, we have
        strived to create a brand which leaves its mark on the gaming scene, and
        help propel small gamers into the spotlight. Whether you’re a casual
        fan, an up-and-coming pro, or a streamer looking to make their mark,
        Killjoy 7 will be there every step of the way. As a business, we offer a
        variety of services to enhance your image, and back you with reputable
        sponsors and dependable resources. As we look to the future of Gaming
        Media, we want to be at the forefront of new ideas, and stay ahead of
        the curve, pushing to innovate and stay competitive in our mission to
        build up the next generation of Content Creators, Gaming Clans, eSports
        Professionals and Organizations dedicated to their gaming passions.
      </div>
    </div>
  );
};

export default AboutHome;
