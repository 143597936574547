import React from "react";
import Footer from "../Footer";
import AdAreaClan from "./AdAreaClan";
import ApplyForClan from "./ApplyForClan";
import ApplyForPartnership from "./ApplyForPartnership";
import ClanCentralAbout from "./ClanCentralAbout";
import ClanCentralIntro from "./ClanCentralIntro";
import K7Partners from "./K7Partners";

const ClanCentral = () => {
  return (
    <div>
      <ClanCentralIntro />
      <ClanCentralAbout />
      <div className="section-separator" data-aos="fade-up"></div>
      <K7Partners />
      <div className="section-separator" data-aos="fade-up"></div>
      {/* <ApplyForClan />
      <ApplyForPartnership />  */}
      {/* <div className="section-separator" data-aos="fade-up"></div> */}
      <AdAreaClan />
      <Footer />
    </div>
  );
};

export default ClanCentral;
