import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const K7HomeIntro = () => {
  AOS.init({ duration: 1500, once: true });

  return (
    <div className="line-wrapper">
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <div className="test-bg">
        <div data-aos="fade-up" className="title-intro">
          We are <span className="k7-color-theme">Killjoy</span>
        </div>
      </div>
    </div>
  );
};

export default K7HomeIntro;
