import React from "react";
import { useHistory } from "react-router";
import { leagueStandingsArray1 } from "../../k7LeagueStandings1";
import { leagueStandingsArray2 } from "../../k7LeagueStandings2";

const K7LeagueStandings = () => {
  const history = useHistory();

  const toK7League = () => {
    history.push("/k7League");
  };

  return (
    <div className="ls-background">
      <div className="ls-intro-wrapper">
        <div data-aos="fade-up" data-aos-delay="350" className="ls-title">
          The <span className="k7-color-theme">K7 League</span>{" "}
        </div>
        <div data-aos="fade-up" data-aos-delay="400" className="ls-intro">
          With over 2-years of competitive experience, the K7 League aims to be
          a platform for upcoming amateur talent to showcase their skill. We
          want to provide a professional-grade of amateur gameplay experience to
          our players through our engagement with organizations and activities.
          <br />
          <br />
          Our mission is a clear and simple one. It is to organize and develop
          an independent league of players. It all starts at the league level
          where we scout for members throughout free agency and display the
          potential and capacity to join the competitive team. Our vision for K7
          League is to build the ultimate eSports venture for players. We are
          looking to establish a strong and fierce presence within the eSports
          community.
        </div>
      </div>
      <div className="ls-wrapper">
        <div data-aos="fade-up" data-aos-delay="450" className="ls-table">
          <div className="division-title">Division Alpha</div>
          <table>
            <thead className="ls-table-header">
              <tr>
                <th>POS</th>
                <th>Team</th>
                <th>W</th>
                <th>L</th>
                <th>GP</th>
                <th>W/L</th>
              </tr>
            </thead>

            {leagueStandingsArray1.slice(0, 4).map((item, index) => (
              <tbody className="ls-table-row" key={index}>
                <tr>
                  <td>{item.leaguePos}</td>
                  <td className="ls-standing-images">
                    <img src={item.leagueLogo} alt="" /> {item.leagueTeam}
                  </td>
                  <td>{item.leagueWin}</td>
                  <td>{item.leagueLoss}</td>
                  <td>{item.leagueGP}</td>
                  <td>
                    {(
                      (+item.leagueWin / (+item.leagueWin + +item.leagueLoss)) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>

        <div data-aos="fade-up" data-aos-delay="500" className="ls-table">
          <div className="division-title">Division Bravo</div>
          <table>
            <thead className="ls-table-header">
              <tr>
                <th>POS</th>
                <th>Team</th>
                <th>W</th>
                <th>L</th>
                <th>GP</th>
                <th>W/L</th>
              </tr>
            </thead>

            {leagueStandingsArray2.slice(0, 4).map((item, index) => (
              <tbody className="ls-table-row" key={index}>
                <tr>
                  <td>{item.leaguePos}</td>
                  <td className="ls-standing-images">
                    <img src={item.leagueLogo} alt="" /> {item.leagueTeam}
                  </td>
                  <td>{item.leagueWin}</td>
                  <td>{item.leagueLoss}</td>
                  <td>{item.leagueGP}</td>
                  <td>
                    {(
                      (+item.leagueWin / (+item.leagueWin + +item.leagueLoss)) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>

      <div
        className="ls-button-wrapper"
        data-aos="fade-up"
        data-aos-delay="400"
      >
        <button onClick={toK7League} className="ls-button">
          Check League Standings
        </button>
      </div>
    </div>
  );
};

export default K7LeagueStandings;
