import React from "react";

const K7PreviousMatches = () => {
  const previousMatches = [
    {
      ytSource: "https://www.youtube.com/embed/xBzthMC9qU8",
    },
    {
      ytSource: "https://www.youtube.com/embed/sXZAIfXDBPU",
    },
  ];

  return (
    <div>
      <div className="section-titles" data-aos="fade-up">Previous Clips</div>
      <div className="previous-matches-wrapper">
        {previousMatches.map((item, index) => (
          <div key={index} data-aos="fade-up" data-aos-delay="300">
            <iframe
              src={item.ytSource}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default K7PreviousMatches;
