import React from "react";

const K7services = () => {
  // usEffect(() => {
  // function preventDefault(e) {
  //   e.preventDefault();
  // }

  // function disableScroll() {
  //   document.body.addEventListener("touchmove", preventDefault, {
  //     passive: false,
  //   });
  // }

  // function enableScroll() {
  //   document.body.removeEventListener("touchmove", preventDefault);
  // }
  // }, []);

  const k7Services = [
    {
      id: 1,
      serviceIcon: "fas fa-briefcase",
      serviceTitle: "Brand Development",
      serviceDescription: "",
    },
    {
      id: 2,
      serviceIcon: "fas fa-chart-line",
      serviceTitle: "Social Media & Marketing",
      serviceDescription: "",
    },
    {
      id: 3,
      serviceIcon: "fas fa-chalkboard",
      serviceTitle: "Leadership Development",
      serviceDescription: "",
    },
    {
      id: 4,
      serviceIcon: "fas fa-calendar-week",
      serviceTitle: "Event Planning",
      serviceDescription: "",
    },
    {
      id: 5,
      serviceIcon: "fab fa-discord",
      serviceTitle: "Discord Support",
      serviceDescription: "",
    },
    {
      id: 6,
      serviceIcon: "fas fa-laptop-code",
      serviceTitle: "Website Development",
      serviceDescription: "",
    },
    {
      id: 7,
      serviceIcon: "fas fa-laptop-code",
      serviceTitle: "Consultation",
      serviceDescription: "",
    },
    {
      id: 8,
      serviceIcon: "fas fa-laptop-code",
      serviceTitle: "Recruiting",
      serviceDescription: "",
    },
    {
      id: 9,
      serviceIcon: "fas fa-laptop-code",
      serviceTitle: "Content Creation",
      serviceDescription: "",
    },
  ];

  const serviceOpen = () => {
    document.body.classList.add("service-open");
    // disableScroll();
  };

  const serviceClose = () => {
    document.body.classList.remove("service-open");
    // enableScroll();
  };

  return (
    <div className="services-background">
      <div className="service-style">
        <h1 data-aos="fade-up" data-aos-delay="250">
          Our Services
        </h1>
        <div className="services-wrapper">
          {k7Services.map((item, index) => (
            <a
              data-aos="fade-up"
              data-aos-delay="300"
              key={index}
              href={`#modal-${item.id}`}
            >
              <div className="service" onClick={serviceOpen}>
                <i className={item.serviceIcon}></i>
                <h2>{item.serviceTitle}</h2>
              </div>
            </a>
          ))}
        </div>
      </div>

      {/* {array.map((item, index) => (
        <div key={index} id={`modal-${item.id}`} className="modalDialog">
          <div>
            <a href="#close" title="Close" className="close">
              x
            </a>
            <h2>{item.serviceTitle}</h2>
            <p>Place Holder</p>
            <br />
            <hr color="#303ef7" />
            <br />
            <ul className="dashed">
              <li>Assist in creating a mission statement</li>
              <li>Help create goals and a vision</li>
              <li>
                Identify any gaps and weaknesses and provide services to
                strengthen the community
              </li>
              <li>Help develop and transistion into new concepts</li>
            </ul>
          </div>
        </div>
      ))} */}

      <div id="modal-1" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Brand Development</h2>
          {/* <p>Place Holder</p> */}
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            <li>Assist in creating a mission statement</li>
            <li>Help create goals and a vision</li>
            <li>
              Identify any gaps and weaknesses and provide services to
              strengthen the community
            </li>
            <li>Help develop and transistion into new concepts</li>
          </ul>
        </div>
      </div>

      <div id="modal-2" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Social Media &#38; Marketing</h2>
          {/* <p>Place Holder</p> */}
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            <li>
              Provide dedicated promotions, advertisement &#38; highlights
              monthly
            </li>
            <li>Design custom artwork tailored to your community</li>
            <li>
              Endorse your community &#38; brand via social media announcements
            </li>
            <li>Advertiseing events as well as shaping social media content</li>
          </ul>
        </div>
      </div>

      <div id="modal-3" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Leadership Development</h2>
          {/* <p>Place Holder</p> */}
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            <li>Provide leadership guidance &#38; mentoring</li>
            <li>Host leadership &#38; community building seminars</li>
          </ul>
        </div>
      </div>

      <div id="modal-4" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Event Planning</h2>
          {/* <p>Place Holder</p> */}
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            <li>Develop an event(s) promotion strategy</li>
            <li>Provide staff for community events(based on availability)</li>
            <li>Assistance with the organization of events</li>
          </ul>
        </div>
      </div>

      <div id="modal-5" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Discord Support</h2>
          {/* <p>Place Holder</p> */}
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            <li>Server automation and organization</li>
            <li>Administrative support</li>
            <li>Securing &#38; hardening your community server</li>
            <li>Cyber security support &#38; news</li>
          </ul>
        </div>
      </div>

      <div id="modal-6" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Website Development</h2>
          <p>
            Building amazing websites is our passion. We work with the client on
            a personal level to make sure it is what they are looking for.
            Ensuring a website is beautiful, customizable, and has a responsive
            user experience is our priority. All our product are unique and
            custom to our client's desire.
          </p>
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            <li>Interview client to understand what they need</li>
            <li>
              Design a concept to give an idea of what the website will contain
            </li>
            <li>
              Develop the website using manual code to ensure custom and
              beautiful designs
            </li>
            <li>
              Review website with client to ensure quality before final product
              is delivered
            </li>
            <li>Optional - Can keep us on retainer for monthly fee</li>
          </ul>
        </div>
      </div>

      <div id="modal-7" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Consultation</h2>
          <p>
            Professional advisory committee to your Brand or Business, offering
            a variety of exercises, strategies and conferencing to provide you
            with end to end user support in all scenarios.
          </p>
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            {/* <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li> */}
          </ul>
        </div>
      </div>

      <div id="modal-8" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Recruiting</h2>
          <p>
            Our team searches out and helps builds the best teams we can for
            your organization, with extra focus on skill sets, personality and
            drive. Let us help you build your roster.
          </p>
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            {/* <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li> */}
          </ul>
        </div>
      </div>

      {/* <div id="modal-9" className="modalDialog">
        <div>
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={serviceClose}
          >
            x
          </a>
          <h2>Content Creation</h2>
          <p></p>
          <br />
          <hr color="gold" />
          <br />
          <ul className="dashed">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};

export default K7services;
