import React, { Component } from "react";
import Slider from "react-slick";
import DA from "../../images/Dark_Assassins.png";
import Wardens from "../../images/Wardens.png";
import XDG from "../../images/XDG_Ad_2.png";
import K7SN from "../../images/k7SN.png";

export default class CenterMode extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2500,
    };
    return (
      <div className="ad-area-wrapper" data-aos="fade-up" data-aos-delay="300">
        <div className="section-titles">K7 Advertisements</div>
        {/* <div className="ad-title">Ad area</div> */}
        <Slider {...settings}>
          <div>
            <img src={DA} alt=""></img>
          </div>
          <div>
            <img src={Wardens} alt=""></img>
          </div>
          <div>
            <img src={XDG} alt=""></img>
          </div>
          <div>
            <img src={K7SN} alt=""></img>
          </div>
         
        </Slider>
      </div>
    );
  }
}
