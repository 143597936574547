import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const K7LeagueIntro = () => {
  AOS.init({ duration: 1500, once: true });

  return (
    <div className="line-wrapper">
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <div data-aos="fade-up" className="league-intro">
        <div>
          Welcome to <span className="k7-color-theme">K7 League</span>
        </div>
      </div>
    </div>
  );
};

export default K7LeagueIntro;
