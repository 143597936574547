import React from "react";
import { k7Teams } from "../../teamsData";

const K7Teams = () => {
  return (
    <div className="background-section-seperator">
      <div className="section-titles" data-aos="fade-up">
        K7 Teams
      </div>
      <div className="k7-teams-wrapper">
        {k7Teams.map((item, index) => (
          <div
            key={index}
            className="k7-team-card"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div>
              {" "}
              <div>
                <img src={`${item.k7TeamLogo}`} alt="" />
              </div>
            </div>

            <div>
              <div className="k7-teams-name">{item.k7TeamName}</div>
              <ul className="k7-teams-social-icons">
                <li className={`${item.k7YoutubeIcon ? "" : "remove-padding"}`}>
                  <a href={item.k7TeamYoutube} target="_blank">
                    <i className={`${item.k7YoutubeIcon}`}></i>
                  </a>
                </li>
                <li className={`${item.k7DiscordIcon ? "" : "remove-padding"}`}>
                  <a href={item.k7TeamDiscord} target="_blank">
                    <i className={`${item.k7DiscordIcon}`}></i>
                  </a>
                </li>
                <li className={`${item.k7TwitterIcon ? "" : "remove-padding"}`}>
                  <a href={item.k7TeamTwitter} target="_blank">
                    <i className={`${item.k7TwitterIcon}`}></i>
                  </a>
                </li>
                <li className={`${item.k7TiktokIcon ? "" : "remove-padding"}`}>
                  <a href={item.k7TeamTiktok} target="_blank">
                    <i className={`${item.k7TiktokIcon}`}></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default K7Teams;
