import React, { useState } from "react";
import { Link } from "react-router-dom";
import K7Logo from "../images/K7Logo.png";

const Menu = () => {
  let pathname = window.location.pathname;
  const [btnClass, setBtnClass] = useState(false);

  // if(btnClass) {
  //   document.body.classList += " menu--open";
  // }

  btnClass
    ? (document.body.classList += " menu--open")
    : document.body.classList.remove("menu--open");

  // function closeMenu() {
  //   document.body.classList.remove("menu--open");
  // }

  return (
    <div>
      <ul className={"navbar-list"}>
        <div className="brand-name">
          <li style={{ display: "flex" }}>
            <img style={{ width: "50px" }} src={K7Logo} alt="" />
          </li>
        </div>

        <div className="list-nav">
          <li className={`${pathname.match("/home") ? "link-active" : ""}`}>
            <Link to="/home">Home</Link>
          </li>
          <li className={`${pathname.match("/k7League") ? "link-active" : ""}`}>
            <Link to="/k7League">K7 League</Link>
          </li>
          <li
            className={`${
              pathname.match("/clan-central") ? "link-active" : ""
            }`}
          >
            <Link to="/clan-central">Clan Central</Link>
          </li>
          {/* <li className={`${pathname.match("/staff") ? "link-active" : ""}`}>
            <Link to="/staff">Staff</Link>
          </li> */}
          {/* <li className={`${pathname.match("/sponsors") ? "link-active" : ""}`}>
            <Link to="/sponsors">Sponsors</Link>
          </li> */}
          <li className={`${pathname.match("/contact") ? "link-active" : ""}`}>
            <Link to="/contact">Contact Us</Link>
          </li>
        </div>
      </ul>

      <div
        id="hamburger"
        onClick={() => {
          btnClass ? setBtnClass(false) : setBtnClass(true);
        }}
      >
        {btnClass ? (
          <i className="fas fa-times"></i>
        ) : (
          <i className="fas fa-bars"></i>
        )}
      </div>

      <div id="sidebar" className={btnClass ? "btnClass clicked" : "btnClass"}>
        <ul className={btnClass ? "side-bar-list clicked" : "side-bar-list"}>
          <li>
            <Link to="/home">Home</Link>
          </li>
          <li>
            <Link to="/k7League">K7 League</Link>
          </li>
          <li>
            <Link to="/clan-central">Clan Central</Link>
          </li>
          {/* <li>
            <Link to="/staff">Staff</Link>
          </li>
          <li>
            <Link to="/sponsors">Sponsors</Link>
          </li> */}
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
