import React from "react";
import UpcomingMatchesImg1 from "../../images/K7Logo.png";
import testLogo from "../../images/K7Logo.png";
import star from "../../images/K7TeamLogos/5star.png";
import ctc from "../../images/K7TeamLogos/ctc.png";
import doc from "../../images/K7TeamLogos/DoC.png";
import kgk from "../../images/K7TeamLogos/kgk.png";
import lurk from "../../images/K7TeamLogos/Lurk-Blue.png";
import m7 from "../../images/K7TeamLogos/m7.png";
import nt from "../../images/K7TeamLogos/newthreat.png";
import nola from "../../images/K7TeamLogos/Nola.png";
import nta from "../../images/K7TeamLogos/nt.png";
import pul from "../../images/K7TeamLogos/Pulsive-NA.png";
import syg from "../../images/K7TeamLogos/syg.png";
import tfs from "../../images/K7TeamLogos/tfs.png";
import tv from "../../images/K7TeamLogos/tv.png";
import zo from "../../images/K7TeamLogos/z2o.png";

const UpcomingMatches = () => {
  const upcomingMatches1 = [
    {
      images1: syg,
      images2: zo,
      teamName: "",
      schedule: "02/21",
    },
    {
      images1: nt,
      images2: testLogo,
      teamName: "",
      schedule: "02/21",
    },
    {
      images1: tfs,
      images2: ctc,
      teamName: "",
      schedule: "02/21",
    },
    {
      images1: pul,
      images2: doc,
      teamName: "",
      schedule: "02/21",
    },
    {
      images1: tv,
      images2: m7,
      teamName: "",
      schedule: "02/22",
    },
    {
      images1: star,
      images2: testLogo,
      teamName: "",
      schedule: "02/22",
    },
    {
      images1: nola,
      images2: kgk,
      teamName: "",
      schedule: "02/22",
    },
    {
      images1: lurk,
      images2: nta,
      teamName: "",
      schedule: "02/22",
    },
    {
      images1: ctc,
      images2: syg,
      teamName: "",
      schedule: "02/23",
    },
    {
      images1: doc,
      images2: tfs,
      teamName: "",
      schedule: "02/23",
    },
    {
      images1: nt,
      images2: pul,
      teamName: "",
      schedule: "02/23",
    },
    {
      images1: zo,
      images2: testLogo,
      teamName: "",
      schedule: "02/23",
    },
    {
      images1: kgk,
      images2: tv,
      teamName: "",
      schedule: "02/24",
    },
    {
      images1: nta,
      images2: nola,
      teamName: "",
      schedule: "02/24",
    },
    {
      images1: lurk,
      images2: star,
      teamName: "",
      schedule: "02/24",
    },
    {
      images1: m7,
      images2: testLogo,
      teamName: "",
      schedule: "02/24",
    },
  ];

  return (
    <div className="um-background">
      <div className="section-titles" data-aos="fade-up">
        Upcoming Matches <br /> Season is over, thanks for tuning in!<br/> Be sure to follow us on twitter @K7League
      </div>
     
      {/* <div className="um-card-date" data-aos="fade-up" data-aos-delay="250">
        <div>Day 1</div>
        <div className="um-card-month">February 21</div>
      </div>
      <div className="um-card-wrapper">
        {upcomingMatches1.slice(0, 4).map((item, index) => (
          <div
            key={index}
            className="um-card"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <img src={item.images1} className="um-team-cards" alt=""></img>
            <div className="um-date">{item.schedule}</div>
            <img src={item.images2} className="um-team-cards" alt=""></img>
          </div>
        ))}
      </div>

      <div className="um-card-date" data-aos="fade-up" data-aos-delay="250">
        <div>Day 2</div>
        <div className="um-card-month">February 22</div>
      </div>

      <div className="um-card-wrapper">
        {upcomingMatches1.slice(4, 8).map((item, index) => (
          <div
            key={index}
            className="um-card"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <img src={item.images1} className="um-team-cards" alt=""></img>
            <div className="um-date">{item.schedule}</div>
            <img src={item.images2} className="um-team-cards" alt=""></img>
          </div>
        ))}
      </div>

      <div className="um-card-date" data-aos="fade-up" data-aos-delay="250">
        <div>Day 3</div>
        <div className="um-card-month">February 22</div>
      </div>

      <div className="um-card-wrapper">
        {upcomingMatches1.slice(8, 12).map((item, index) => (
          <div
            key={index}
            className="um-card"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <img src={item.images1} className="um-team-cards" alt=""></img>
            <div className="um-date">{item.schedule}</div>
            <img src={item.images2} className="um-team-cards" alt=""></img>
          </div>
        ))}
      </div>

      <div className="um-card-date" data-aos="fade-up" data-aos-delay="250">
        <div>Day 4</div>
        <div className="um-card-month">February 23</div>
      </div>

      <div className="um-card-wrapper">
        {upcomingMatches1.slice(12, 16).map((item, index) => (
          <div
            key={index}
            className="um-card"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <img src={item.images1} className="um-team-cards" alt=""></img>
            <div className="um-date">{item.schedule}</div>
            <img src={item.images2} className="um-team-cards" alt=""></img>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default UpcomingMatches;
