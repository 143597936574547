import emailjs from "emailjs-com";
import React, { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../Footer";

const Contact = () => {
  AOS.init({ duration: 1500, once: true });
  const form = useRef();
  let clicks = 0;

  const sendEmail = (e) => {
    e.preventDefault();
    const nameCheck = document.getElementById("name").value.length;
    const emailCheck = document.getElementById("email").value.length;
    const subjectCheck = document.getElementById("subject").value.length;
    const messageCheck = document.getElementById("message").value.length;
    const formMessage = document.getElementById("form-message");

    if (clicks < 2) {
      if (
        nameCheck !== 0 &&
        emailCheck > 12 &&
        subjectCheck !== 0 &&
        messageCheck !== 0
      ) {
        formMessage.style.transform = "scale(1)";
        formMessage.innerHTML = "Message is being sent...";
        formMessage.style.fontStyle = "italic";

        emailjs
          .sendForm(
            "service_jiwqqll",
            "template_lj681kn",
            form.current,
            "user_UCUVwnsHoI5TY3a8SyOoy"
          )
          .then((result) => {
            if (result.text === "OK") {
              e.target.reset();
              clicks++;
              formMessage.innerHTML = "Success!! Message is sent";
              formMessage.style.fontStyle = "normal";

              setTimeout(() => {
                formMessage.style.transform = "scale(0)";
              }, 7500);
            }
          });
      } else {
        return;
      }
    } else {
      return;
    }
  };

  return (
    <div className="contact-section">
      <div className="contact-header" data-aos="fade-up">
        {/* Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
        amet..", comes from a line in section 1.10.32.ontrary to popular belief,
        Lorem Ipsum is not simply random text. It has roots in a piece of
        classical Latin literature from 45 BC, making it over */}
      </div>
      <form
        ref={form}
        onSubmit={sendEmail}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="input-wrapper">
          <label>Name*</label>
          <br />
          <input id="name" type="text" name="name" required />
        </div>

        <div className="input-wrapper">
          <label>Email*</label>
          <br />
          <input id="email" type="email" name="email" required />
        </div>

        <div className="input-wrapper">
          <label>Subject*</label>
          <br />
          <input id="subject" type="text" name="subject" required />
        </div>

        <div className="input-wrapper">
          <label>Message*</label>
          <br />
          <textarea id="message" name="message" required />
        </div>

        <div id="form-message"></div>

        <div className="contact-button-wrapper">
          <button id="contact-button" type="submit">
            Send
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default Contact;
