import React from "react";

const ClanCentralAbout = () => {
  return (
    <div className="clan-central-text" data-aos="fade-up" data-aos-delay="50">
      {/* Killjoy 7 is a media company focused around Gaming and building Gaming
      Platforms for players all over the world. Established in 2018, we have
      strived to create a brand which leaves its mark on the gaming scene, and
      help propel small gamers into the spotlight. Whether you’re a casual fan,
      an up-and-coming pro, or a streamer looking to make their mark, Killjoy 7
      will be there every step of the way. As a business, we offer a */}
    </div>
  );
};

export default ClanCentralAbout;
