import React from "react";
import { leagueStandingsArray1 } from "../../k7LeagueStandings1";
import { leagueStandingsArray2 } from "../../k7LeagueStandings2";

const LeagueStandings = () => {
  return (
    <div
      className="ls-background"
      style={{ backgroundImage: "none", background: "#111111" }}
    >
      <div className="section-titles" data-aos="fade-up">
        K7 League Standings
      </div>
      <div className="ls-wrapper">
        <div className="ls-table" data-aos="fade-up" data-aos-delay="200">
          <table>
            <thead className="ls-table-header">
              <tr>
                <th>POS</th>
                <th>Team</th>
                <th>W</th>
                <th>L</th>
                <th>GP</th>
                <th>W/L</th>
              </tr>
            </thead>

            {leagueStandingsArray1.map((item, index) => (
              <tbody className="ls-table-row" key={index}>
                <tr>
                  <td>{item.leaguePos}</td>
                  <td className="ls-standing-images">
                    <img src={item.leagueLogo} alt="" /> {item.leagueTeam}
                  </td>
                  <td>{item.leagueWin}</td>
                  <td>{item.leagueLoss}</td>
                  <td>{item.leagueGP}</td>
                  <td>
                    {(
                      (+item.leagueWin / (+item.leagueWin + +item.leagueLoss)) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>

        <div className="ls-table" data-aos="fade-up" data-aos-delay="200">
          <table>
            <thead className="ls-table-header">
              <tr>
                <th>POS</th>
                <th>Team</th>
                <th>W</th>
                <th>L</th>
                <th>GP</th>
                <th>W/L</th>
              </tr>
            </thead>

            {leagueStandingsArray2.map((item, index) => (
              <tbody className="ls-table-row" key={index}>
                <tr>
                  <td>{item.leaguePos}</td>
                  <td className="ls-standing-images">
                    <img src={item.leagueLogo} alt="" /> {item.leagueTeam}
                  </td>
                  <td>{item.leagueWin}</td>
                  <td>{item.leagueLoss}</td>
                  <td>{item.leagueGP}</td>
                  <td>
                    {(
                      (+item.leagueWin / (+item.leagueWin + +item.leagueLoss)) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeagueStandings;
