import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const ClanCentralIntro = () => {
  AOS.init({ duration: 1500, once: true });

  return (
    <div className="line-wrapper">
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <div className="clan-intro-wrapper" data-aos="fade-up">
        <div className="clan-text-wrapper">
          <div className="clan-cental-title">
            Welcome to <span className="k7-color-theme">Clan Central</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClanCentralIntro;
