import React, { useEffect } from "react";
import K7Teams from "./K7Teams";
import K7SliderTeams from "./K7SliderTeams";
import K7PreviousMatches from "./K7PreviousMatches";
import UpcomingMatches from "./UpcomingMatches";
import LeagueStandings from "./LeagueStandings";
import K7LeagueIntro from "./K7LeagueIntro";
import Footer from "../Footer";

const K7League = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <K7LeagueIntro />
      <K7Teams />
      <K7SliderTeams />
      <div className="section-separator" data-aos="fade-up"></div>
      <LeagueStandings />
      <div className="section-separator" data-aos="fade-up"></div>
      <UpcomingMatches />
      <div className="section-separator" data-aos="fade-up"></div>
      <K7PreviousMatches />
      <Footer />
    </div>
  );
};

export default K7League;
