import K7services from "./K7services";
import K7LeagueStandings from "./K7LeagueStandings.js";
import K7HomeIntro from "./K7HomeIntro";
import AboutHome from "./AboutHome";
import Footer from "../Footer";

const Home = () => {
  return (
    <div>
      <K7HomeIntro />
      <AboutHome />
      <div className="section-separator" data-aos="fade-up"></div>
      <K7services />
      <K7LeagueStandings />
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Home;
